
	import { Component, Vue, Watch } from "vue-property-decorator";
	import FloorSelector from "../../components/floorSelector/floorSelector.vue";
	import request from "@/request";
	import Hls from "hls.js";
	import Loading from "@/components/loading/Loading.vue";
	import { Page, Table, TableColumn, Input } from "view-design";
	import { Route } from "vue-router";
	import { ECharts, EChartsOption, init } from "echarts";
	const axios = require("axios");

	@Component({
        components: {
            FloorSelector,
            Loading,
            Table,
            Page,
            Input
        }
    })
		export default class SmtVideo extends Vue {
            linshiImg = true;
        colors = ["#4261ED", "#EF9F24", "#c4ccD6", "#FD4848"];
        chooseVideo = 0;
        isDrawBack = false;
        videoUrl = "https://open.ys7.com/v3/openlive/K32964538_1_1.m3u8?expire=1721266252&id=603519463601475584&t=6cc3a8ffd15d39dedbf5d737b337fed4f5c65c6ef2b8a2076fd587290a347e7c&ev=100";
        videoUrl2 = "https://open.ys7.com/v3/openlive/K32964538_14_2.m3u8?expire=1719826339&id=597480030565572608&t=4dfe2fe193b73fdfe393469e08fed8fb86593f38f1709d5a3aa068fbb19ebcbd&ev=100";
        options = {
            autoplay: true, // 如果true,浏览器准备好时开始播放。
            muted: true, // 默认情况下将会消除任何音频。
            loop: true, // 导致视频一结束就重新开始。
            preload: "auto", // auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: "zh-CN", // 汉化
            fluid: true, // 当true时，播放器将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [{
            type: "application/x-mpegURL",
                src: this.videoUrl // 视频播放地址
            }],
            notSupportedMessage: "此视频暂无法播放，请稍后再试", // 无法播放媒体源时显示的默认信息。
            controlBar: {
            timeDivider: true,
            durationDisplay: true, // 视频时长时间显示
            remainingTimeDisplay: false, // 剩余时间显示
            fullscreenToggle: true // 全屏按钮
            },
            errorDisplay: false, // 错误显示
            posterImage: true, // 视频的预览海报图片显示
            bigPlayButton: true, // 视频中间的播放按钮显示
            textTrackDisplay: false
        };
        player: any = {};
        floors: { name: string; id: number }[] = [];
        loading: boolean = true;
        data: any[] = [];
        videoList: any[] = [];
        tableLoading: boolean = true;
        page: number = 1;
        total: number = 0;
        isCollect: number = 0; // 1表示收藏，0表示没收藏
        searchValue: string = "";
        readonly columns: TableColumn[] = [
            {
            title: "编号",
            key: "code",
            width: 150
            },
            {
            title: "品牌",
            key: "brand"
            },
            {
            title: "类型",
            key: "type"
            },
            {
            title: "在线状态",
            key: "status"
            }
        ];
        private readonly id: string = "id" + Math.ceil(Math.random() * 1000);
        private hls?: Hls;

        get height (): number {
            return document.getElementsByClassName("mainContent2")[0]
            ? document.getElementsByClassName("index")[0].clientHeight - 490
            : 400;
        }
        scrollTop=0;// 滚动条位置
        offsetHeight=0;// 可视区高
        scrollHeight=0;// 滚动区域
        mypage = 1;
        mycount = 0;
        shipinCount:any = {
            online: 0,
            offline: 0
        }
        myId:any = "";
        floorData:any = {
            online: 0,
            offline: 0,
            name: "1F"
        }
        onFind (event) {
            let img = event.srcElement;
            img.src = require("../../assets/icon/example.png");
            img.onerror = null;
        }
  pageChange (page: number = 1) {
    this.tableLoading = !0;
    this.page = page;
    this.data = [];
    request({
      url: "/smt/rest/camera/",
      params: {
        floor: this.$store.state.floorValue,
        pagesize: Math.floor((this.height - 40) / 50),
        page
      }
    }).then(({ data }) => {
      this.data = data.results.map((t: any) => ({
        code: t.code,
        name: t.name,
        brand: t.ctype ? t.ctype.brand : "",
        type: t.ctype ? t.ctype.name : "",
        status: ["离线", "在线"][t.onLineStatus],
        id: t.id,
        cameraUuid: t.cameraUuid,
        floor: t.floor ? t.floor.match(/\/\d+\//)[0].replace(/\//g, "") : "",
        guid: t.guid
      }));
      this.total = data.count;
      this.tableLoading = !1;
    });
  }

  onSelect (value: any) {
    this.$store.commit("updateLiveStream", [value.id, value.cameraUuid].join());
    if (value.floor) {
      this.$store.commit("findFloorAndCamera", [value.floor, value.guid]);
    }
  }
    returnImg (item) {
        let str = `/user/djangouser/${ this.myId }/`;
        let myIndex = -9;
        item.users.forEach((item, index) => {
            if (item.indexOf(str) > -1) {
                myIndex = index;
            }
        });
        return myIndex > -1 ? require("../../assets/icon/JC_056.png") : require("../../assets/icon/JC_055.png");
    }
    searchCollect () {
        this.isCollect = this.isCollect == 1 ? 0 : 1;
        this.getVideoList(1);
    }
    // 点击收藏
    clickCollect (item) {
        let winStr = window.location.origin.indexOf("bimsheng") > -1 ? window.location.origin : "https://hsdch.bimsheng.com";
        let str = `/user/djangouser/${ this.myId }/`;
        let myIndex = -9;
        item.users.forEach((item, index) => {
            if (item.indexOf(str) > -1) {
                myIndex = index;
            }
        });
        if (myIndex > -1) {
            item.users.splice(myIndex, 1);
        } else {
            item.users.push(`${ winStr }/user/djangouser/${ this.myId }/`);
        }
        request({
            url: `/smt/rest/camera/${ item.id }/`,
            method: "patch",
            data: {
                users: item.users
            }
        }).then(() => {
            this.$Message.info(myIndex > -1 ? "已取消收藏" : "成功收藏");
        });
    }
    // 点击切换视频地址
    clickSelcetVideo (item:any, type:any) {
        // console.log(document.getElementById(`sys_ponit_video_${item.id}`), type, `sys_ponit_video_${item.id}`);
        if(type == "needIcon"){
            if(document.getElementById(`sys_ponit_video_${item.id}`)){
                // 清除
                let tempArr:any = document.getElementsByClassName("sys_ponit_2");
                if(tempArr.length > 0){
                    tempArr?.forEach(item => {
                        item.style.outline = "none";
                    })
                }
                // 选中
                document.getElementById(`sys_ponit_video_${item.id}`).style.outline = "3px solid #4261ed";
            }else{
                this.$Message.info("暂无数据");
            }
        }

        this.chooseVideo = item.id;
        request({
            url: `/hikv/cameraddr/?cameraid=${ item.id }`
            // url: `/hikv/cameraddr/?cameraid=${ item.id }&protocol=rtmp`
        }).then(({ data }) => {
            let url :string = data.url;
            // let url :string = `${ window.location.origin }/stream/${ item.ip }/index.m3u8`;
            // let url :string = "http://192.168.2.32/stream/192.168.3.226/index.m3u8";
            if (window.location.origin.indexOf("bimsheng") > -1 || window.location.origin.indexOf("8080") > -1) {
                url = "https://open.ys7.com/v3/openlive/K32964538_14_2.m3u8?expire=1719826339&id=597480030565572608&t=4dfe2fe193b73fdfe393469e08fed8fb86593f38f1709d5a3aa068fbb19ebcbd&ev=100";
            }
            console.log(url, "视频地址");
            this.player.src(url); // 重置video的src
            this.player.load(url); // 使video重新加载
        });
    }
    getFloorData () {
        request({
            url: `/space/floor/${ this.$store.state.floorId || "1000178" }/`
        }).then(({ data }) => {
            this.floorData.name = data.name;
        });
        request({
            url: `/smt/rest/camera/online/?floor=${ this.$store.state.floorId || "1000178" }`
        }).then(({ data }) => {
            this.floorData.online = data.online;
            this.floorData.offline = data.offline;
        });
    }
  // 获得视频列表数据
  getVideoList (page = 1) {
    this.mypage = page;
    let Temp:any = {
            room__floor: this.$store.state.floorId || "1000178",
            pagesize: 20,
            page: this.mypage
        };
        if (this.isCollect == 1) {
            Temp.users = this.myId;
        }
        if (this.searchValue) {
            Temp.search = this.searchValue;
        }
    request({
        url: "/smt/rest/camera/?format=json",
        params: Temp
    }).then(({ data }) => {
        this.mycount = data.count;
        this.videoList = page === 1 ? data.results : this.videoList.concat(data.results);
        if (this.mypage === 1) {
            document.getElementById("video_box").scrollTop = 0;
        }
        // this.clickSelcetVideo(this.videoList[0]);
    });
  }
    setChart3 (domName, timeData = [], data1 = [], data2 = []) {
        const myChart: HTMLElement = document.getElementById(domName) as HTMLElement;
        // 获取dom，断言HTMLElement类型，否则会报错
        const chart = init(myChart);
        let myOption:any = {
            // legend: {
            //     data: ["今日", "昨日"],
            //     itemWidth: 13,
            //     itemHeight: 13,
            //     textStyle: {
            //         color: "#788CA1"
            //     },
            //     top: 30
            // },
            grid: {
                top: "5%",
                left: "5%",
                right: "4%",
                bottom: "5%",
                containLabel: true
            },
            xAxis: {
                type: "category",
                boundaryGap: false,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#c4ccD6"
                    }
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: "dashed",
                        color: ["#e0e2e3"]
                    }
                },
                axisTick: {
                    lineStyle: {
                        width: 0
                    }
                },
                axisLabel: {
                    color: "#788CA1"
                },
                data: timeData
            },
            yAxis: {
                type: "value",
                nameTextStyle: {
                    fontSize: 10,
                    align: "center"
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: "dashed",
                        color: ["#e0e2e3"]
                    }
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#c4ccD6"
                    }
                },
                axisLabel: {
                    color: "#788CA1"
                }
            },
            tooltip: {
                trigger: "axis"
            },
            series: [
                {
                    name: "在线",
                    type: "line",
                    smooth: true,
                    symbol: "none",
                    areaStyle: {
                        opacity: 0.5,
                        color: {
                            type: "linear",
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: this.colors[0] // 0% 处的颜色
                            }, {
                                offset: 1, color: "#ffffff" // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    },
                    data: data1
                },
                {
                    name: "掉线",
                    type: "line",
                    smooth: true,
                    symbol: "none",
                    data: data2,
                    areaStyle: {
                        opacity: 0.5,
                        color: {
                            type: "linear",
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: this.colors[1] // 0% 处的颜色
                            }, {
                                offset: 1, color: "#ffffff" // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    }
                }
            ],
            color: this.colors
        };
        chart.setOption(myOption);
    }
    bindScroll () {
        this.scrollTop = document.getElementById("video_box").scrollTop;
        this.scrollHeight = document.getElementById("video_box").scrollHeight;
        this.offsetHeight = document.getElementById("video_box").offsetHeight;
    }
    getData () {
        request({
            url: "/smt/rest/camera/online/"
        }).then(({ data }) => {
            this.shipinCount.online = data.online;
            this.shipinCount.offline = data.offline;
        });

        request({
            url: "/smt/rest/camera/online/history/?pagesize=8"
        }).then(({ data }) => {
            let time = []; let data1 = []; let data2 = [];
            data.results.forEach(element => {
                time.push(element.create_time.slice(5, 10));
                data1.push(element.online);
                data2.push(element.offline);
            });
            // this.setChart3("echart", time, data1, data2);
        });
        request("/user/self/").then(({ data }) => {
            this.myId = data.data.id;
            console.log(this.myId, "user");
        });
    }
    mounted () {
        this.getData();
        if (this.$store.state.showType !== "room") {
            this.$store.commit("changeViewByCode", { type: "room", id: "" });
        }
        this.$store.commit("updateCameraStatus", true);
        request({
        url: "/space/floor/",
        params: {
            facility: 1
        }
        }).then(({ data }) => {
            this.floors = data.reverse().map((t: { name: string; id: string }) => ({
                name: t.name,
                id: t.id
            }));
        });
        // @ts-ignore
        const video: HTMLVideoElement = document.getElementById(this.id);
        this.listeners = this.$store.subscribe((mutation) => {
            if (mutation.type === "updateLiveStream") {
                if (this.hls) {
                this.hls.stopLoad();
                this.hls.detachMedia();
                }
                const hls = new Hls();
                hls.loadSource(`/mag/hls/${ mutation.payload.split(",")[1] }/1/live.m3u8`);
                hls.attachMedia(video);
                this.loading = true;
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                video.play();
                this.loading = false;
                });
                this.hls = hls;
            } else if (mutation.type === "changeViewByCode") {
                this.pageChange();
            }
        });
        if (Hls.isSupported()) {
            const hls = new Hls();
            this.hls = hls;
            hls.loadSource("/mag/hls/6136737bc3c2486f910d94f8292bbb72/1/live.m3u8");
            hls.attachMedia(video);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                video.play();
                this.loading = false;
            });
        }
        // 弃用
        // this.pageChange();
        // 视频初始化 20240424 暂时不用
        // @ts-ignore
        // this.player = this.$video(this.$refs.videoPlayer, this.options);
        // this.player.play();
        // 视频列表
        this.getVideoList();
        this.getFloorData();
        // 翻页滚动
        let _this = this;
        this.$nextTick(() => {
            document.getElementById("video_box").onscroll = function () {
                _this.bindScroll();
            };
        });
    }
    @Watch("scrollTop")
    changscrollTop () {
        if (this.scrollHeight <= (this.offsetHeight + this.scrollTop)) {
                // console.log("拉取更多数据", this.mypage * 20, this.count);
            if ((this.mypage * 20) < this.mycount) {
                this.mypage++;
                this.getVideoList(this.mypage);
            } else {
                this.$Message.info("已经到底啦");
            }
        }
    }
    @Watch("$store.state.shebeiModal")
    changeshebeiModal () {
        console.log(this.$store.state.shebeiContent,9999);
        // 暂时取消 2024.4.24 2397 2874
        // this.clickSelcetVideo(this.$store.state.shebeiContent, "");
        this.linshiImg = this.$store.state.shebeiContent.id != 2397 && this.$store.state.shebeiContent.id != 2874 ;
    }
    @Watch("$store.state.floorId")
    changeFloorId () {
        this.mypage = 1;
        this.getVideoList(this.mypage);
        this.getFloorData();
    }
    @Watch("isDrawBack")
    onisDrawBackchange (val) {
        this.$store.commit("changeDraw", val);
    }

  beforeRouteLeave (to: Route, from: Route, next: Function) {
    this.listeners();
    if (this.hls) {
      this.hls.stopLoad();
      this.hls.detachMedia();
      this.hls.destroy();
    }
    this.$store.commit("updateCameraStatus", false);
    next();
  }

  private listeners () { }
	}

